import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { useCachedDates } from "@rentiohq/shared-frontend/dist/hooks/useCachedDates";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as activityActions from "@rentiohq/shared-frontend/dist/redux/activity/activity.actions";
import * as activitySelectors from "@rentiohq/shared-frontend/dist/redux/activity/activity.selectors";
import { useCount } from "@rentiohq/shared-frontend/dist/redux/count/count.hooks";
import { ACTIVITY_SUBTYPES } from "@rentiohq/shared-frontend/dist/types/activity.types";
import {
  EPaymentOrderOwnerPaymentMethod,
  EPaymentOrderType,
  EPayoutType,
} from "@rentiohq/shared-frontend/dist/types/payment.types";
import { EPaymentRequestFeature } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import {
  endOfMonth,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Card,
  DataTable,
  EFilterType,
  EmptyState,
  Error,
  Filters,
  mapItemsToDataTableProps,
  Pagination,
  translateDateRange,
  TSortDirection,
} from "@rentiohq/web-shared/dist/components";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { compact } from "lodash";
import hash from "object-hash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import usePreference from "scenes/Settings/hooks/usePreference";
import { NumberParam } from "serialize-query-params";
import { getItems } from "./PaymentHistory.utils";

interface IProps {
  propertyId?: number;
}

enum EPaymentHistoryIncoming {
  Incoming = "incoming",
  // MatchedPayin = "matched_payin",
  // UnmatchedPayin = "unmatched_payin",
  Repudiations = "repudiations",
}

enum EPaymentHistoryOutgoing {
  Payout = "payout",
  FromRent = "from_rent",
  MarkedAsPaid = "marked_as_paid",
  // MatchedPayout = "matched_payout",
  // Refund = "refund",
}

enum EPaymentHistoryPaymentSource {
  Rentio = "rentio",
  ThirdPartyAccount = "third_party_account",
}

enum EPaymentHistoryPaymentDateQuick {
  Today = "today",
  ThisWeek = "this_week",
  ThisMonth = "this_month",
  PreviousMonth = "previous_month",
  DateRange = "date_range",
}

export const RECURRING_PAYMENT_TYPES = [
  EPaymentOrderType.Rent,
  EPaymentOrderType.CommonCosts,
  EPaymentOrderType.NonCommonCosts,
  EPaymentOrderType.ManagementCosts,
  EPaymentOrderType.Rentio,
  EPaymentOrderType.OtherRecurring,

  EPaymentOrderType.RentDiscount,
];

export const ONE_TIME_PAYMENT_TYPES = [
  EPaymentOrderType.LocationDescription,
  EPaymentOrderType.BrokerFee,
  EPaymentOrderType.OtherOneoff,

  EPaymentOrderType.IndexationCorrection,
  EPaymentOrderType.IndexationRetroactive,
  EPaymentOrderType.RentDeposit,
  EPaymentOrderType.RentDepositPayout,
];

export const PaymentHistory = (props: IProps) => {
  const { propertyId } = props;

  const items = getItems({ propertyId });
  const itemsMapped = mapItemsToDataTableProps(items);

  const PREFERENCE_KEY_PAYMENT_HISTORY_QUERY = `PAYMENT_HISTORY_QUERY${
    propertyId ? `_${propertyId}` : ""
  }`;
  const PREFERENCE_KEY_PAYMENT_HISTORY_INCOMING = `PAYMENT_HISTORY_INCOMING${
    propertyId ? `_${propertyId}` : ""
  }`;
  const PREFERENCE_KEY_PAYMENT_HISTORY_OUTGOING = `PAYMENT_HISTORY_OUTGOING${
    propertyId ? `_${propertyId}` : ""
  }`;
  const PREFERENCE_KEY_PAYMENT_HISTORY_PAYMENT_SOURCE = `PAYMENT_HISTORY_PAYMENT_SOURCE${
    propertyId ? `_${propertyId}` : ""
  }`;
  const PREFERENCE_KEY_PAYMENT_HISTORY_PAYMENT_TYPE_RECURRING = `PAYMENT_HISTORY_PAYMENT_TYPE_RECURRING${
    propertyId ? `_${propertyId}` : ""
  }`;
  const PREFERENCE_KEY_PAYMENT_HISTORY_PAYMENT_TYPE_ONE_OFF = `PAYMENT_HISTORY_PAYMENT_TYPE_ONE_OFF${
    propertyId ? `_${propertyId}` : ""
  }`;
  const PREFERENCE_KEY_PAYMENT_HISTORY_PAYMENT_DATE_QUICK = `PAYMENT_HISTORY_PAYMENT_DATE_QUICK${
    propertyId ? `_${propertyId}` : ""
  }`;
  const PREFERENCE_KEY_PAYMENT_HISTORY_PAYMENT_DATE_RANGE = `PAYMENT_HISTORY_PAYMENT_DATE_RANGE${
    propertyId ? `_${propertyId}` : ""
  }`;

  // State
  const { nowCached, startOfDay, endOfDay } = useCachedDates();

  const [sort, setSort] = useState<{
    field: string;
    direction: TSortDirection;
  }>({
    field: "datetime",
    direction: "DESC",
  });

  const [, setQueryParamValue] = useQueryParams({
    paymentRequestId: NumberParam,
  });

  const [page, setPage] = useState<number>(1);

  const [query = "", setQuery] = usePreference<string>({
    preferenceKey: PREFERENCE_KEY_PAYMENT_HISTORY_QUERY,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [incoming = [], setIncoming] = usePreference<EPaymentHistoryIncoming[]>(
    {
      preferenceKey: PREFERENCE_KEY_PAYMENT_HISTORY_INCOMING,
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    },
  );

  const [outgoing = [], setOutgoing] = usePreference<EPaymentHistoryOutgoing[]>(
    {
      preferenceKey: PREFERENCE_KEY_PAYMENT_HISTORY_OUTGOING,
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    },
  );

  const [paymentSource = [], setPaymentSource] = usePreference<
    EPaymentHistoryPaymentSource[]
  >({
    preferenceKey: PREFERENCE_KEY_PAYMENT_HISTORY_PAYMENT_SOURCE,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [paymentTypeRecurring = [], setPaymentTypeRecurring] = usePreference<
    typeof RECURRING_PAYMENT_TYPES
  >({
    preferenceKey: PREFERENCE_KEY_PAYMENT_HISTORY_PAYMENT_TYPE_RECURRING,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [paymentTypeOneOff = [], setPaymentTypeOneOff] = usePreference<
    typeof ONE_TIME_PAYMENT_TYPES
  >({
    preferenceKey: PREFERENCE_KEY_PAYMENT_HISTORY_PAYMENT_TYPE_ONE_OFF,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [paymentDateQuick, setPaymentDateQuick, clearPaymentDateQuick] =
    usePreference<EPaymentHistoryPaymentDateQuick>({
      preferenceKey: PREFERENCE_KEY_PAYMENT_HISTORY_PAYMENT_DATE_QUICK,
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    });

  const [paymentDateRange, setPaymentDateRange, clearPaymentDateRange] =
    usePreference<[Date | null, Date | null]>({
      preferenceKey: PREFERENCE_KEY_PAYMENT_HISTORY_PAYMENT_DATE_RANGE,
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    });

  // Redux
  const dispatch = useDispatch();

  const _filter = useMemo(() => {
    const propertyFilter = propertyId
      ? {
          propertyId,
        }
      : undefined;

    const paymentRequestInFilter = (() => {
      if (incoming.length === 0) {
        return undefined;
      }

      const result: { [key: string]: any } = {
        payoutType: EPayoutType.Charge,
      };

      if (incoming.includes(EPaymentHistoryIncoming.Repudiations)) {
        result.features = {
          like: `%${EPaymentRequestFeature.DisputePayment}%`,
        };
      }

      return result;
    })();

    const paymentRequestOutFilter = (() => {
      if (outgoing.length === 0) {
        return undefined;
      }

      const result: { [key: string]: any } = {
        payoutType: EPayoutType.Payout,
      };

      if (outgoing.includes(EPaymentHistoryOutgoing.MarkedAsPaid)) {
        result.paidManuallyAt = { neq: null };
      }

      return result;
    })();

    // TODO: Not in use because impossible to filter with PO & PR as separate filters
    const paymentOrderInFilter = (() => {
      if (incoming.length === 0) {
        return undefined;
      }

      const result: { [key: string]: any } = {
        // processMethod: EPayoutType.Charge,
      };

      if (Object.keys(result).length === 0) {
        return undefined;
      }

      return result;
    })();

    const paymentOrderOutFilter = (() => {
      if (outgoing.length === 0) {
        return undefined;
      }

      const result: { [key: string]: any } = {
        // processMethod: EPayoutType.Payout,
      };

      if (outgoing.includes(EPaymentHistoryOutgoing.FromRent)) {
        result.ownerPaymentMethod = EPaymentOrderOwnerPaymentMethod.FromRent;
      }

      if (Object.keys(result).length === 0) {
        return undefined;
      }

      return result;
    })();

    const paymentRequestSourceFilter = (() => {
      if (
        paymentSource.length === 0 ||
        paymentSource.length ===
          Object.values(EPaymentHistoryPaymentSource).length
      ) {
        return undefined;
      }

      switch (paymentSource[0]) {
        case EPaymentHistoryPaymentSource.Rentio:
          return {
            features: {
              nlike: `%${EPaymentRequestFeature.ThirdPartyPayInBrokerDirect}%`,
            },
          };

        case EPaymentHistoryPaymentSource.ThirdPartyAccount:
          return {
            features: {
              like: `%${EPaymentRequestFeature.ThirdPartyPayInBrokerDirect}%`,
            },
          };

        default:
          return undefined;
      }
    })();

    const paymentOrderTypeFilter = (() => {
      if (paymentTypeRecurring.length === 0 && paymentTypeOneOff.length === 0) {
        return undefined;
      }

      return {
        type: {
          inq: [...paymentTypeRecurring, ...paymentTypeOneOff],
        },
      };
    })();

    const dateFilter = (() => {
      switch (paymentDateQuick) {
        case EPaymentHistoryPaymentDateQuick.Today:
          return {
            datetime: {
              between: [startOfDay, endOfDay],
            },
          };

        case EPaymentHistoryPaymentDateQuick.ThisWeek:
          return {
            datetime: {
              between: [startOfWeek(nowCached), endOfDay],
            },
          };

        case EPaymentHistoryPaymentDateQuick.ThisMonth:
          return {
            datetime: {
              between: [startOfMonth(nowCached), endOfDay],
            },
          };

        case EPaymentHistoryPaymentDateQuick.PreviousMonth:
          const nowLastMonth = subMonths(nowCached, 1);

          return {
            datetime: {
              between: [startOfMonth(nowLastMonth), endOfMonth(nowLastMonth)],
            },
          };

        case EPaymentHistoryPaymentDateQuick.DateRange: {
          const start = paymentDateRange?.[0];
          const end = paymentDateRange?.[1];

          if (start && end) {
            return {
              datetime: {
                between: [start, end],
              },
            };
          }

          return undefined;
        }

        default:
          return undefined;
      }
    })();

    let paymentRequestInOutFilters = undefined;
    if (paymentRequestInFilter && paymentRequestOutFilter) {
      paymentRequestInOutFilters = {
        or: [paymentRequestInFilter, paymentRequestOutFilter],
      };
    } else {
      paymentRequestInOutFilters =
        paymentRequestInFilter || paymentRequestOutFilter;
    }

    const paymentRequestWheres = compact([
      paymentRequestSourceFilter,
      paymentRequestInOutFilters,
    ]);

    let paymentOrderInOutFilters = undefined;
    if (paymentOrderInFilter && paymentOrderOutFilter) {
      paymentOrderInOutFilters = {
        or: [paymentOrderInFilter, paymentOrderOutFilter],
      };
    } else {
      paymentOrderInOutFilters = paymentOrderInFilter || paymentOrderOutFilter;
    }

    const paymentOrderWheres = compact([
      paymentOrderTypeFilter,
      paymentOrderInOutFilters,
    ]);

    const result: { [key: string]: any } = {
      order: `${sort.field} ${sort.direction}`,
      search: query.length > 0 ? query : undefined,
      where: {
        and: compact([
          {
            subtype: {
              inq: [
                // TODO: Ibanisation?
                ACTIVITY_SUBTYPES.PAYMENT.PAYIN_SUCCEEDED,
                ACTIVITY_SUBTYPES.PAYMENT.PAYOUT_SUCCEEDED,
                ACTIVITY_SUBTYPES.PAYMENT.REQUEST_MANUALLY_PAID,
                // ACTIVITY_SUBTYPES.PAYMENT.RENT_DISCOUNT_SKIPPED,
                // ACTIVITY_SUBTYPES.PAYMENT.RENT_DISCOUNT_LATE,
                ACTIVITY_SUBTYPES.PAYMENT.PAYIN_DISPUTED,
                // ACTIVITY_SUBTYPES.PAYMENT
                //   .IBANISATION_MATCHING_NO_OLDEST_AMOUNT_MATCH,
                // ACTIVITY_SUBTYPES.PAYMENT
                //   .IBANISATION_MATCHING_NO_OPEN_PAYMENT_REQUESTS,
                // ACTIVITY_SUBTYPES.PAYMENT
                //   .IBANISATION_MATCHING_NO_REFERENCE_MATCH_MULTIPLE_PAYMENT_REQUESTS_WITH_AMOUNT,
                // ACTIVITY_SUBTYPES.PAYMENT
                //   .IBANISATION_MATCHING_NO_REFERENCE_MATCH_NO_AMOUNT_MATCH,
                // ACTIVITY_SUBTYPES.PAYMENT
                //   .IBANISATION_MATCHING_NO_REFERENCE_MATCH_TOOK_CORRESPONDING_AMOUNT,
                // ACTIVITY_SUBTYPES.PAYMENT
                //   .IBANISATION_MATCHING_NO_REFERENCE_MATCH_TOOK_OLDEST,
                // ACTIVITY_SUBTYPES.PAYMENT.IBANISATION_MATCHING_NO_AMOUNT_MATCH,
                // ACTIVITY_SUBTYPES.PAYMENT.IBANISATION_MATCHING_TOOK_OLDEST,
                // ACTIVITY_SUBTYPES.PAYMENT
                //   .IBANISATION_MATCHING_MULTIPLE_PAYMENT_REQUESTS,
                // ACTIVITY_SUBTYPES.PAYMENT.IBANISATION_MATCHING_MANUAL,
                // ACTIVITY_SUBTYPES.PAYMENT
                //   .IBANISATION_MATCHING_MANUAL_PARTIAL_MATCH,
                // ACTIVITY_SUBTYPES.PAYMENT
                //   .IBANISATION_MATCHING_MANUAL_BALANCE_AVAILABLE,
              ],
            },
          },
          propertyFilter,
          dateFilter,
        ]),
      },
      paymentRequestWhere:
        paymentRequestWheres.length > 1
          ? {
              and: paymentRequestWheres,
            }
          : paymentRequestWheres[0],
      paymentOrderWhere:
        paymentOrderWheres.length > 1
          ? {
              and: paymentOrderWheres,
            }
          : paymentOrderWheres[0],
    };

    return result;
  }, [
    sort,
    propertyId,
    query,
    incoming,
    outgoing,
    paymentSource,
    paymentTypeRecurring,
    paymentTypeOneOff,
    paymentDateQuick,
    paymentDateRange,
  ]);

  const [filter] = useDebounce(_filter);

  const identifier = `payment-history-activities-${hash(filter)}`;

  const isFetching = useSelector((state: IRootStore) =>
    activitySelectors.isFetchingActivitiesForPage(state, identifier, page),
  );

  const fetchError = useSelector((state: IRootStore) =>
    activitySelectors.activitiesForPageFetchError(state, identifier, page),
  );

  const activities = useSelector((state: IRootStore) =>
    activitySelectors.getActivitiesPaged(state, identifier, page),
  );

  const { order: _order, ...filterForCount } = filter;
  const countBase = append("/activities/count", filterForCount);

  const { count = 0 } = useCount({
    shouldRefetch: true,
    countBase,
  });

  const pageCount = Math.ceil(count / CONFIG.DEFAULT_FETCH_LIMIT);

  // Lifecycle
  useEffect(() => {
    if (isFetching) {
      return;
    }

    dispatch(
      activityActions.getActivitiesPaged.actions.start({
        limit: CONFIG.DEFAULT_FETCH_LIMIT,
        page,
        identifier,
        filterData: filter,
      }),
    );
  }, [filter, page, identifier]);

  // Event handlers
  const handleSort = (headingIndex: number, direction: TSortDirection) => {
    const sortItem = itemsMapped.orderMap[headingIndex];
    if (!sortItem) {
      return;
    }

    setSort({ field: sortItem, direction });
  };

  const handleQueryChange = (newQuery: string) => {
    setPage(1);
    setQuery(newQuery);
  };

  const handleQueryClear = () => {
    setQuery("");
  };

  // Render
  const renderFilter = () => (
    <Filters
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryClear}
      queryValue={query}
      queryPlaceholder={getLocalizedText(
        "payment_history.filters.query.placeholder",
      )}
      filterConfigs={[
        {
          label: getLocalizedText("payment_history.filter.in_out.title"),
          groupKey: "in_out",
          filters: [
            {
              label: getLocalizedText(
                "payment_history.filter.in_out.section.in.title",
              ),
              filterKey: "incoming",
              type: EFilterType.MultiSelect,
              values: incoming,
              options: Object.values(EPaymentHistoryIncoming),
              onChange: newValues => {
                setPage(1);
                setIncoming([...newValues]);
                setOutgoing([]);
              },
              onRemove: (key: string) => {
                setIncoming(incoming.filter(v => v !== key));
              },
              translate: (value: any) =>
                getLocalizedText(
                  `payment_history.filter.in_out.section.in.option.${value}`.toLowerCase(),
                ),
            },
            {
              label: getLocalizedText(
                "payment_history.filter.in_out.section.out.title",
              ),
              filterKey: "outgoing",
              type: EFilterType.MultiSelect,
              values: outgoing,
              options: Object.values(EPaymentHistoryOutgoing),
              onChange: newValues => {
                setPage(1);
                setOutgoing([...newValues]);
                setIncoming([]);
              },
              onRemove: (key: string) => {
                setOutgoing(outgoing.filter(v => v !== key));
              },
              translate: (value: any) =>
                getLocalizedText(
                  `payment_history.filter.in_out.section.out.option.${value}`.toLowerCase(),
                ),
            },
            {
              label: getLocalizedText(
                "payment_history.filter.in_out.section.payment_source.title",
              ),
              filterKey: "payment_source",
              type: EFilterType.SingleSelect,
              values: paymentSource,
              options: Object.values(EPaymentHistoryPaymentSource),
              onChange: newValues => {
                setPage(1);
                setPaymentSource([...newValues]);
              },
              onRemove: (key: string) => {
                setPaymentSource(paymentSource.filter(v => v !== key));
              },
              translate: (value: any) =>
                getLocalizedText(
                  `payment_history.filter.in_out.section.payment_source.option.${value}`.toLowerCase(),
                ),
            },
          ],
        },
        {
          label: getLocalizedText("payment_history.filter.payment_type.title"),
          groupKey: "payment_type",
          filters: [
            {
              label: getLocalizedText(
                "payment_history.filter.payment_type.section.recurring.title",
              ),
              filterKey: "payment_type_recurring",
              type: EFilterType.MultiSelect,
              values: paymentTypeRecurring,
              options: RECURRING_PAYMENT_TYPES,
              onChange: newValues => {
                setPage(1);
                setPaymentTypeRecurring([...newValues]);
              },
              onRemove: (key: string) => {
                setPage(1);
                setPaymentTypeRecurring(
                  paymentTypeRecurring.filter(v => v !== key),
                );
              },
              translate: (value: any) =>
                getLocalizedText(
                  `payment.payment_order.type.${value}`.toLowerCase(),
                ),
            },
            {
              label: getLocalizedText(
                "payment_history.filter.payment_type.section.one_off.title",
              ),
              filterKey: "payment_type_one_off",
              type: EFilterType.MultiSelect,
              values: paymentTypeOneOff,
              options: ONE_TIME_PAYMENT_TYPES,
              onChange: newValues => {
                setPage(1);
                setPaymentTypeOneOff([...newValues]);
              },
              onRemove: (key: string) => {
                setPage(1);
                setPaymentTypeOneOff(paymentTypeOneOff.filter(v => v !== key));
              },
              translate: (value: any) =>
                getLocalizedText(
                  `payment.payment_order.type.${value}`.toLowerCase(),
                ),
            },
          ],
        },
        {
          label: getLocalizedText("payment_history.filter.payment_date.title"),
          groupKey: "payment_date",
          filters: [
            {
              label: getLocalizedText(
                "payment_history.filter.payment_date.section.quick.title",
              ),
              filterKey: "payment_date_quick",
              type: EFilterType.SingleSelect,
              values: paymentDateQuick ? [paymentDateQuick] : undefined,
              options: Object.values(EPaymentHistoryPaymentDateQuick),
              onChange: newValues => {
                setPage(1);
                setPaymentDateQuick(newValues?.[0]);
                clearPaymentDateRange();
              },
              showInAppliedFilters: newValues =>
                newValues?.[0] !== EPaymentHistoryPaymentDateQuick.DateRange,
              onRemove: () => {
                clearPaymentDateQuick();
              },
              translate: (value: any) =>
                getLocalizedText(
                  `payment.payment_order.type.${value}`.toLowerCase(),
                ),
            },
            {
              label: getLocalizedText(
                "payment_history.filter.payment_date.section.date_range.title",
              ),
              filterKey: "payment_date_range",
              type: EFilterType.MonthRange,
              minStartDate: new Date(2019, 0, 1),
              maxStartDate: new Date(),
              minEndDate: new Date(2019, 0, 1),
              maxEndDate: new Date(),
              values: paymentDateRange
                ? {
                    start:
                      typeof paymentDateRange[0] === "string"
                        ? new Date(paymentDateRange[0])
                        : paymentDateRange[0],
                    end:
                      typeof paymentDateRange[1] === "string"
                        ? new Date(paymentDateRange[1])
                        : paymentDateRange[1],
                  }
                : undefined,
              onChange: (newValues: {
                start: Date | null;
                end: Date | null;
              }) => {
                setPaymentDateRange([newValues.start, newValues.end]);
                setPaymentDateQuick(EPaymentHistoryPaymentDateQuick.DateRange);
              },
              onRemove: () => {
                clearPaymentDateRange();
              },
              translate: value => translateDateRange(value),
            },
          ],
        },
      ]}
    />
  );

  const renderPaging = () => {
    if (pageCount <= 1) {
      return null;
    }

    return (
      <Pagination
        initialPage={page ? page - 1 : 0}
        pageCount={pageCount}
        onPageChange={({ selected }) => setPage(selected + 1)}
      />
    );
  };

  return (
    <Card
      heading={getLocalizedText("payments.follow_up.payment_history.heading")}
    >
      {renderFilter()}

      <Spacer weight={ESpacerWeight.W16} />

      {fetchError && !isFetching && !activities ? (
        <Error errors={[fetchError]} />
      ) : (
        <DataTable
          isLoadingData={isFetching}
          horizontalSpacing="extraTight"
          emptyState={
            <EmptyState
              backgroundColor="gray"
              heading={getLocalizedText("payment_history.overview.empty")}
            />
          }
          {...itemsMapped}
          onSort={handleSort}
          sortColumnIndex={itemsMapped.orderMap.indexOf(sort.field)}
          sortDirection={sort.direction}
          onRowClick={(rowIndex: number) => {
            setQueryParamValue({
              paymentRequestId: activities?.[rowIndex]?.paymentRequestId,
            });
          }}
          rows={compact(activities).map(activity => ({
            id: activity.id,
            content: items.map(item => item.renderContent(activity)),
            actions: [
              // {
              //   content: getLocalizedText("payment_order.view_detail.action"),
              //   onClick: () => {
              //     setQueryParamValue({
              //       paymentOrderId: paymentOrder.id,
              //     });
              //   },
              // },
            ],
          }))}
        />
      )}

      {renderPaging()}
    </Card>
  );
};
