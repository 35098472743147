import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { EContractIndexationStatus } from "../../types/contract.types";
import { EPaymentOrderType } from "../../types/payment.types";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { ETypeSelect } from "./schema.createPayment.0";
import { EField, NON_RECURRING_COSTS, RECURRING_COSTS, } from "./schema.createPayment.types";
import { getNavigationTitle, getPaymentTypeIcon, getRelatedContract, isOnceCostDisabled, isRecurringCostDisabled, } from "./schema.createPayment.utils";
var PAYMENT_ORDER_TYPES_BROKER = [
    // Recurring
    EPaymentOrderType.ManagementCosts,
    EPaymentOrderType.Rentio,
    // Non recurring
    EPaymentOrderType.BrokerFee,
];
export var getSchema = function (initialValues, _a) {
    var isBroker = _a.isBroker, isTenant = _a.isTenant, paymentOrder = _a.paymentOrder, activeContracts = _a.activeContracts, endedContracts = _a.endedContracts, property = _a.property, internalModeEnabled = _a.internalModeEnabled, bookkeepingEnabled = _a.bookkeepingEnabled;
    return function (defaultValues) {
        var _a;
        var contract = getRelatedContract({
            isBroker: isBroker,
            property: property,
            isTenant: isTenant,
            paymentOrder: paymentOrder,
            activeContracts: activeContracts,
            endedContracts: endedContracts,
            bookkeepingEnabled: bookkeepingEnabled,
        }, defaultValues);
        return {
            required: [],
            properties: (_a = {},
                _a[EField.Header + "1"] = {
                    default: undefined,
                },
                _a[EField.Info] = {
                    default: undefined,
                    // @ts-ignore
                    showIf: function () {
                        return defaultValues[EField.TypeSelect] === ETypeSelect.Recurring &&
                            (contract === null || contract === void 0 ? void 0 : contract.indexationStatus) === EContractIndexationStatus.Ready;
                    },
                },
                _a[EField.TypeRecurring] = {
                    default: getValue(EField.TypeRecurring, initialValues, defaultValues),
                    // @ts-ignore
                    requiredIf: function (formData) {
                        return contract && formData[EField.TypeSelect] === ETypeSelect.Recurring;
                    },
                    // @ts-ignore
                    options: RECURRING_COSTS.filter(function (cost) {
                        if (PAYMENT_ORDER_TYPES_BROKER.includes(cost)) {
                            return isBroker;
                        }
                        return true;
                    }).map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("payment.payment_order.type.".concat(id.toLowerCase())),
                        icon: getPaymentTypeIcon(id),
                        disabled: isRecurringCostDisabled(id, isTenant, contract, internalModeEnabled),
                    }); }),
                    handleChange: function (fieldName) {
                        if (fieldName === EField.TypeOnce) {
                            return { shouldSetNewValue: true, newValue: undefined };
                        }
                        return { shouldSetNewValue: false };
                    },
                    // @ts-ignore
                    showIf: function (formData) {
                        return (formData[EField.TypeSelect] || ETypeSelect.Once) ===
                            ETypeSelect.Recurring;
                    },
                },
                _a[EField.TypeOnce] = {
                    default: getValue(EField.TypeOnce, initialValues, defaultValues),
                    // @ts-ignore
                    requiredIf: 
                    //
                    function (formData) { return formData[EField.TypeSelect] === ETypeSelect.Once; },
                    // @ts-ignore
                    options: 
                    //
                    NON_RECURRING_COSTS.filter(function (cost) {
                        if (PAYMENT_ORDER_TYPES_BROKER.includes(cost)) {
                            return isBroker;
                        }
                        return true;
                    }).map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("payment.payment_order.type.".concat(id.toLowerCase())),
                        icon: getPaymentTypeIcon(id),
                        disabled: isOnceCostDisabled(id, isTenant),
                    }); }),
                    handleChange: function (fieldName) {
                        if (fieldName === EField.TypeRecurring) {
                            return { shouldSetNewValue: true, newValue: undefined };
                        }
                        return { shouldSetNewValue: false };
                    },
                    // @ts-ignore
                    showIf: function (formData) {
                        return (formData[EField.TypeSelect] || ETypeSelect.Once) ===
                            ETypeSelect.Once;
                    },
                },
                _a),
        };
    };
};
var formatSummary = function (formData) {
    return formData &&
        getLocalizedText("payment.payment_order.type.".concat(formData.toLowerCase()));
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("payments.create.type.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData),
            disabledOnSummary: true
        },
        _a[EField.Header + "1"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("payments.create.type.heading"),
        },
        _a[EField.Info] = {
            "ui:field": ERentioFormField.HelpInfo,
            title: getLocalizedText("payment.payment_order.contract_info.heading"),
            subtitle: getLocalizedText("payments.create.contract.info.active_contract"),
        },
        _a[EField.TypeRecurring] = {
            "ui:field": ERentioFormField.InlineSelect,
            "ui:options": {
                variant: "card",
            },
            title: getLocalizedText("payments.create.add_costs.monthly_costs"),
            formatSummary: formatSummary,
            hideOnSummaryIfEmpty: true,
        },
        _a[EField.TypeOnce] = {
            "ui:field": ERentioFormField.InlineSelect,
            "ui:options": {
                variant: "card",
            },
            title: getLocalizedText("payments.create.add_costs.oneoff_costs"),
            formatSummary: formatSummary,
            hideOnSummaryIfEmpty: true,
        },
        _a);
};
