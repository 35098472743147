import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import * as contractActionsV2 from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.actions";
import * as contractSelectorsV2 from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.selectors";
import { EContractFetchType } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.types";
import * as contractUtils from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import * as propertyActions from "@rentiohq/shared-frontend/dist/reduxV2/property/property.actions";
import * as propertySelectors from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { IMember } from "@rentiohq/shared-frontend/dist/types/auth.types";
import {
  EContractMemberTypes,
  EContractType,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { EField, IExtraData } from "./schema.createDocumentPackage.types";

export const customContractSelector = (payload: {
  contractId: string;
  state: IPartialRootState;
}): IContract | undefined => {
  const { state, contractId } = payload;
  const contract = contractSelectorsV2.getDetail(state, contractId);
  return contract;
};

export const customPropertySelector = (payload: {
  propertyId: number;
  state: IPartialRootState;
}): IProperty | undefined => {
  const { state, propertyId } = payload;
  return propertySelectors.getDetail(state, propertyId);
};

export const customContractsSelector = (payload: {
  propertyId: number;
  state: IPartialRootState;
}): any[] => {
  const { propertyId, state } = payload;
  const contractsFilter = contractUtils.getFilterForProperty(
    propertyId,
    EContractFetchType.Active,
  );

  const contracts = contractSelectorsV2.getAllWhere(state, {
    filter: contractsFilter,
  });

  if (contracts?.items) {
    return contracts.items
      .filter(contract => contract.contractType === EContractType.Basic)
      .map(contract => {
        const { startDate, stopDate } = contract;
        const startAndEndDate = stopDate
          ? `${formatDate(startDate)} - ${formatDate(stopDate)}`
          : formatDate(startDate);
        const contractMembers = contract.members
          .filter(
            (member: IMember<EContractMemberTypes>) =>
              member.roles.includes(EContractMemberTypes.Tenant) ||
              member.roles.includes(EContractMemberTypes.Parent),
          )
          .map(
            (member: IMember<EContractMemberTypes>) =>
              `${member.account.firstname} ${member.account.lastname}`,
          )
          .join(", ");

        return {
          id: contract.id,
          value: contract.contractType
            ? `${utils.i18n.t(
                `contract.type.${contract.contractType.toLowerCase()}`,
              )}: ${contractMembers}`
            : contractMembers,
          subValue: startAndEndDate,
        };
      });
  }
  return [];
};

export const customIsFetchingSelector = (
  state: IPartialRootState,
  _: IExtraData,
  formData: any,
) => {
  const propertyId = formData[EField.Property];
  const contractsFilter = contractUtils.getFilterForProperty(
    propertyId,
    EContractFetchType.Active,
  );

  const contracts = contractSelectorsV2.getAllWhere(state, {
    filter: contractsFilter,
  });

  if (contracts) {
    return false;
  }

  return contractSelectorsV2.isFetchingAllWhere(state, {
    filter: contractsFilter,
  });
};

export const customGetContractsActions = (_: IExtraData) => {
  const result = [];

  result.push((formData: any) => {
    const filter = contractUtils.getFilterForProperty(
      formData[EField.Property],
      EContractFetchType.Active,
    );
    // TODO: add refetch?
    // refetch: true,
    return contractActionsV2.getAllWhereStart.getAction({ query: { filter } });
  });

  result.push((formData: any) => {
    return propertyActions.getDetailStart.getAction({
      id: formData[EField.Property],
    });
  });

  return result;
};
