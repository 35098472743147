import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as generalLedgerAccountHooks from "@rentiohq/shared-frontend/dist/reduxV2/generalLedgerAccount/generalLedgerAccount.hooks";
import { IGeneralLedgerAccount } from "@rentiohq/shared-frontend/dist/reduxV2/generalLedgerAccount/generalLedgerAccount.types";
import { EPaymentOrderType } from "@rentiohq/shared-frontend/dist/types/payment.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { PAYMENT_ORDER_SORTING } from "@rentiohq/shared-frontend/dist/utils/paymentOrder.utils";
import {
  Card,
  DataTable,
  EmptyState,
  Error,
  IDataTableItem,
  Loading,
  mapItemsToDataTableProps,
} from "@rentiohq/web-shared/dist/components";
import { compact, indexOf, sortBy } from "lodash";
import { useParams } from "react-router-dom";

interface IProps {
  asCard?: boolean;
}

const visuallyOrderGeneralLedgerAccounts = (input: IGeneralLedgerAccount[]) => {
  return sortBy(input, [
    x => indexOf(PAYMENT_ORDER_SORTING, x.paymentOrderType),
    x => x.typePayment,
    x => x.code,
    x => x.description,
  ]);
};

export const ContactGeneralLedgerAccounts = (props: IProps) => {
  const { asCard = true } = props;

  const params = useParams<{ contactId: string }>();
  const contactId = params.contactId ? +params.contactId : undefined;

  const { contactMeMaster } = authHooks.useSelf();

  // If no contact assume me master
  const {
    items: _items,
    isFetching,
    fetchError,
  } = generalLedgerAccountHooks.usePaged({
    shouldRefetch: true,
    // TODO: Replace actual me-master ID with just me-master (doesn't work yet in new API)
    customPath: `/contacts/${
      contactId || contactMeMaster?.id
    }/general-ledger-accounts`,
    query: {
      page: 1,
      limit: 100,
      sort: [
        { field: "paymentOrderType", method: "ASC" },
        { field: "typePayment", method: "ASC" },
      ],
    },
  });
  const items = _items ? visuallyOrderGeneralLedgerAccounts(_items) : undefined;

  const dataTableItems: IDataTableItem<IGeneralLedgerAccount>[] = [
    {
      heading: getLocalizedText("payment_request.follow_up.payment.heading"),
      columnContentType: "text",
      sortable: false,
      order: undefined,
      renderContent: (item: IGeneralLedgerAccount) => {
        const paymentOrderType = getLocalizedText(
          `payment.payment_order.type.${item.paymentOrderType}`.toLowerCase(),
        );
        const paymentType = item.typePayment
          ? getLocalizedText(
              `payment.payment_order.payment_type.value.${item.typePayment}`.toLowerCase(),
            )
          : undefined;

        let recurrenceType: string | undefined;
        if (item.paymentOrderType === EPaymentOrderType.OtherRecurring) {
          recurrenceType = getLocalizedText(
            "payments.create.add_costs.monthly_costs",
          );
        } else if (item.paymentOrderType === EPaymentOrderType.OtherOneoff) {
          recurrenceType = getLocalizedText(
            "payments.create.add_costs.oneoff_costs",
          );
        }

        return compact([paymentOrderType, paymentType, recurrenceType]).join(
          " - ",
        );
      },
    },
    {
      heading: getLocalizedText("system.entity.general_ledger_account"),
      columnContentType: "text",
      sortable: false,
      order: undefined,
      renderContent: (item: IGeneralLedgerAccount) => item.code,
    },
    {
      heading: getLocalizedText("system.description"),
      columnContentType: "text",
      sortable: false,
      order: undefined,
      renderContent: (item: IGeneralLedgerAccount) => item.description,
    },
  ];
  const dataTableItemsMapped = mapItemsToDataTableProps(dataTableItems);

  const content = (
    <>
      {isFetching && !items && <Loading />}

      {!isFetching && !items && fetchError && <Error errors={[fetchError]} />}

      {items && (
        <>
          {/* <Banner
            variation="info"
            title={getLocalizedText("general_ledger_account.info.title")}
            hasDismiss={false}
          >
            {getLocalizedText("general_ledger_account.info.content")}
          </Banner> */}

          <DataTable
            rows={items.map(item => ({
              id: item.id,
              content: dataTableItems.map(dataTableItem =>
                dataTableItem.renderContent(item),
              ),
            }))}
            {...dataTableItemsMapped}
            emptyState={
              <EmptyState
                heading={getLocalizedText(
                  "general_ledger_accounts.empty.title",
                )}
              >
                {getLocalizedText("general_ledger_accounts.empty.content")}
              </EmptyState>
            }
          />
        </>
      )}
    </>
  );

  if (asCard) {
    return <Card>{content}</Card>;
  }

  return content;
};
