import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import {
  EContractMemberTypes,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import { EDocumentPackageStatus } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  formatCurrency,
  getVat,
} from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { getMembersWithOneOfRoles } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import {
  Button,
  ContactConnectionIndicator,
  ContactFetchListItem,
  Stack,
  TLozengeAppearance,
} from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import { sortBy } from "lodash";
import { rs } from "../services";

export const getContractEditRoute = (contract: IContract, propertyId: number) =>
  rs.updateBasicContractInfoRoute(propertyId, contract.id);

export const renderContractNames = (params: {
  contract: IContract;
  withAdditionalContactMetaData?: boolean;
  vertical?: boolean;
}) => {
  const {
    contract,
    withAdditionalContactMetaData = false,
    vertical = false,
  } = params;
  const renderMemberContact = (contact: IContact) => {
    return (
      <>
        <Button appearance="link" url={`/contacts/${contact.id}`}>
          <ContactConnectionIndicator contact={contact} />
          {getName(contact)}

          {withAdditionalContactMetaData
            ? ` (${utils.contact.getMetaData({ contact })})`
            : undefined}
        </Button>
      </>
    );
  };

  const renderMemberAccount = (account: IAccount) => (
    <>
      <ContactConnectionIndicator forceDisplay={true} />
      {getName(account)}
    </>
  );

  const members = sortBy(
    getMembersWithOneOfRoles(contract.members, [
      EContractMemberTypes.Tenant,
      EContractMemberTypes.Parent,
    ]),
    element => {
      const weight = {
        [EContractMemberTypes.Tenant]: 1,
        [EContractMemberTypes.Parent]: 2,
      };

      const firstRole = element.roles[0] as keyof typeof weight;

      return weight[firstRole];
    },
    "account.firstname",
    "account.lastname",
    "account.company",
  );

  return (
    <Stack vertical={vertical} spacing={vertical ? "extraTight" : "tight"}>
      {members.map(tenant => (
        <Stack.Item key={tenant.account.id}>
          <ContactFetchListItem
            account={tenant.account}
            renderContact={renderMemberContact}
            renderAccount={renderMemberAccount}
          />
        </Stack.Item>
      ))}
    </Stack>
  );
};

export const getContractDates = (contract: IContract) => {
  const items = [utils.date.format(contract.startDate)];
  if (contract.stopDate) {
    items.push(utils.date.format(contract.stopDate));
  }

  return items.join(" - ");
};

export const getContractPrice = (contract: IContract) => {
  const { currentPrice = 0, priceVat = 0 } = contract;

  if (priceVat > 0) {
    return getLocalizedText("system.amount_with_vat", {
      amount: formatCurrency(currentPrice),
      vat: formatCurrency(getVat(currentPrice, priceVat / 100)),
    });
  }

  return formatCurrency(currentPrice);
};

export const getDiscountInfo = (contract: IContract) => {
  const {
    rentDiscountEnabled,
    rentDiscountAmount = 0,
    rentDiscountOffset = 0,
  } = contract;

  if (rentDiscountEnabled) {
    let discountInfo = getLocalizedText(
      "contract.discount.info",
      {
        amount: formatCurrency(rentDiscountAmount ?? 0),
        offset: `${rentDiscountOffset}`,
      },
      rentDiscountOffset || 0,
    );

    return discountInfo;
  }
};

export const getValidDocumentPackage = (contract?: IContract) => {
  if (!contract?.documentPackages) {
    return;
  }

  return contract.documentPackages.filter(
    documentPackage =>
      ![
        EDocumentPackageStatus.Revoked,
        EDocumentPackageStatus.Expired,
        EDocumentPackageStatus.Failed,
        EDocumentPackageStatus.Rejected,
        EDocumentPackageStatus.WorkerFailed,
      ].includes(documentPackage.status!),
  )[0];
};

export const statusToAppearanceMap: { [key: string]: TLozengeAppearance } = {
  draft: "info",
  passed: "default",
  active: "success",
};
